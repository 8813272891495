import * as React from 'react';
import { Moment, default as moment } from 'moment';
import { DataNodePropDate } from '../../../models/DataNodeUtils';
import { DESCRIPTOR_TYPE_PROP } from '@simosol/iptim-data-model';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';

type DatePropEditorProps = {
  item: DataNodePropDate;
  open: boolean;
  onClose: () => void;
};

const EditorDate = (props: DatePropEditorProps) => {
  const { item, open, onClose } = props;
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(moment(item.value));

  const onChange = (date: Date | null) => {
    setSelectedDate(moment(date));
  };

  const onAccept = () => {
    const mDate = selectedDate as unknown as Moment;
    item.value = {
      month: mDate.month(),
      date: mDate.date(),
      year: mDate.year(),
      [DESCRIPTOR_TYPE_PROP]: 'iptim.amf.SimpleDate',
    };
    onClose();
  };

  return (
    <MobileDatePicker
      renderInput={props => <TextField {...props} style={{ display: 'none' }} variant={'standard'}/>}
      open={open}
      onClose={onClose}
      value={selectedDate?.toDate()}
      views={['year', 'month', 'day']}
      onChange={onChange}
      onAccept={onAccept}
    />
  );
};

export default EditorDate;
