import * as React from 'react';

import IconButton, { IconButtonProps } from './IconButton';
import Button, { ButtonProps } from './Button';
import ResultSnackBar from '../views/app/ResultSnackBar';
import { LangKey } from '../LangKey';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/Delete';
import { SvgIconProps } from '@mui/material/SvgIcon';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(1),
  },
}));

type Props = {
  message?: string | string[];
  onClick: () => void,
  onOpen?: () => void,
  icon?: React.ReactElement,
  itemClassName?: string,
  variant: 'button' | 'icon' | 'listItem' | 'menuItem',
  buttonProps?: ButtonProps & IconButtonProps & SvgIconProps,
  div?: boolean,
};

type PropsWithChildren<P> = P & { children?: React.ReactNode };

const Confirm = (props: PropsWithChildren<Props>) => {
  const { message, onClick, onOpen, variant, buttonProps, icon, itemClassName, children, div } = props;
  const styles = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>();

  const handleClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const handleClickAgree = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (e) {
      ResultSnackBar.show(LangKey.CommonUnknownCriticalError.t(), true);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const _onClick = () => {
    onOpen?.();
    setOpen(true);
  };

  let element;
  switch (variant) {
    case 'button':
      const btnProps = { ...buttonProps, loading, onClick: _onClick };
      const child = children || 'OK';
      element = React.createElement(Button, btnProps, child);
      break;
    case 'icon':
      const iconProps = { ...buttonProps, loading, onClick: _onClick };
      const childIcon = children || React.createElement(Delete, { fontSize: 'small' });
      element = React.createElement(IconButton, iconProps, childIcon);
      break;
    case 'listItem':
      element = (
        <ListItem
          disabled={loading}
          onClick={_onClick}
          button
        >
          <ListItemIcon>{icon ? icon : <React.Fragment/>}</ListItemIcon>
          <ListItemText primary={children || 'OK'} />
        </ListItem>
      );
      break;
    case 'menuItem':
      element = (
        <MenuItem disabled={loading} onClick={_onClick}>
          <ListItemIcon className={itemClassName}>
            {icon ? icon : <React.Fragment/>}
          </ListItemIcon>
          <ListItemText primary={children || 'OK'} />
        </MenuItem>
      );
      break;
  }

  const text = message ? message : LangKey.ConfirmDefault.t();

  const content = (
    <>
      {element}
      <Dialog open={open} onClose={handleClose} classes={{ paper: styles.paper }}>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} variant={'contained'}>
            {LangKey.ConfirmNo}
          </Button>
          <Button disabled={loading} onClick={handleClickAgree}  variant={'contained'} color="primary" autoFocus>
            {LangKey.ConfirmYes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return <>{div ? <div>{content}</div> : content}</>;
};

export default Confirm;
