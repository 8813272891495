import * as React from 'react';

import { allLanguages, lang, LangType } from '../../Lang';
import { LangKey } from '../../LangKey';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const useStyles = makeStyles((theme: Theme) => createStyles({
  group: {
    margin: theme.spacing(3, 0),
  },
}));

const LanguageSelector = () => {
  const styles = useStyles();

  const onRadioChange = (event: React.ChangeEvent<{}>, value: string) => {
    lang.change(value as LangType);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{LangKey.SettingsLanguage.t()}</FormLabel>
      <RadioGroup
        className={styles.group}
        value={lang.locale}
        onChange={onRadioChange}
      >
        {allLanguages.map(item => (
          <FormControlLabel
            key={item.lang}
            value={item.lang}
            control={<Radio color={'default'} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default LanguageSelector;
