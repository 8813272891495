import * as React from 'react';
import { default as MuiFab, FabProps as MuiFabProps } from '@mui/material/Fab';
import { Page } from '../Browser';
import { appModel } from '../models/App';

type FabProps = {
  page?: Page,
} & MuiFabProps;

const Fab = (props: FabProps) => {
  const { page, ...newProps } = props;
  newProps.onClick = (e) => {
    if (props.onClick) props.onClick(e);
    if (page !== undefined && appModel.browser.page !== page) {
      appModel.browser.page = page;
    }
  };
  return React.createElement(MuiFab, newProps, newProps.children);
};

export default Fab;
