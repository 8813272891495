import * as React from 'react';
import { appModel } from '../../models/App';
import Auth from '../auth/Auth';
import { observer } from 'mobx-react';
import App from './App';
import AppPreloader from './AppPreloader';
import { configure } from 'mobx';

configure({
  enforceActions: 'never',
});

const AppContainer = observer(() => {
  return (
    <>
      {
        !appModel.authorized
          ? <Auth />
          : appModel.dataInitialized
            ? <App />
            : <AppPreloader progressPercent={appModel.dataInitializePercent} />
      }
    </>
  );
});

export default AppContainer;
