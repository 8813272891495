import * as React from 'react';
import { LangKey } from '../../LangKey';
import { Checkbox, FormControlLabel } from '@mui/material';
import { appModel } from '../../models/App';
import { observer } from 'mobx-react-lite';

const MapStandCheckbox = observer(() => {
  const settings = appModel.settings;
  return (
    <FormControlLabel
      control={ (
        <Checkbox
          checked={settings.allStands}
          onChange={() => settings.allStands = !settings.allStands}
          color="primary"
        />
      )}
      label={LangKey.SettingsAllStand.t()}
    />
  );
});

export default MapStandCheckbox;
