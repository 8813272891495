import * as i18next from 'i18next';
import i18nextXhrBackend from 'i18next-xhr-backend';
import * as React from 'react';
declare global {
  interface String {
    t(p?: object): string;
  }
}

export type LangType = 'es' | 'en' | 'fi' | 'sv' | 'vn' | 'pt';

export type Language = { lang: LangType, label: string };

export const allLanguages: Language[] = [
  { lang: 'en', label: 'English' },
  { lang: 'fi', label: 'Suomeksi' },
  { lang: 'sv', label: 'På svenska' },
  { lang: 'vn', label: 'Tiếng Việt' },
  { lang: 'es', label: 'Español' },
  { lang: 'pt', label: 'Português' },
];

class Lang {
  locale: LangType = 'en';
  label: string = 'English';
  localStorageKey = 'locale';

  private _getLang = (value: string | null): Language => {
    for (const lItem of allLanguages) {
      if (lItem.lang === value) {
        return lItem;
      }
    }
    return { lang: 'en', label: 'English' };
  }

  init = async (onFinished: () => void) => {
    const lg = this._getLang(localStorage.getItem(this.localStorageKey));
    this.locale = lg.lang;
    this.label = lg.label;
    await i18next.use(i18nextXhrBackend).init(  // .use(LanguageDetector)
      {
        lng: this.locale,
        fallbackLng: 'en',
        debug: false, // Utils.DEBUG,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: './locales/{{lng}}.json',
          crossDomain: true,
        },
        nsSeparator: '|',
      },
      () => {
        String.prototype.t = function (this: string, p?: object): string {
          return i18next.t(this.valueOf(), p) as string;
        };

        onFinished();
      },
    );
  }

  change = (lang: LangType) => {
    localStorage.setItem(this.localStorageKey, lang);
    i18next.changeLanguage(lang);
    location.reload();
  }
}

export const lang = new Lang();

export class Utils {
  static translateStringChildren(children: React.ReactNode, p?: Object) {
    return React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return child.t(p);
      } else {
        return child;
      }
    });
  }
}
