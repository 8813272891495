import * as React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Utils } from '../Lang';

type TextProps = {
  p?: Object;
  disableTranslation?: boolean;
} & TypographyProps;

const Text = (props: TextProps) => {
  const { p, disableTranslation, ...newProps } = props;
  if (!newProps.variant) newProps.variant = 'body2';
  let children = props.children;
  if (!disableTranslation) {
    children = Utils.translateStringChildren(props.children, p);
  }
  return React.createElement(Typography, newProps, children);
};

export default Text;
