import { LangKey } from '../../LangKey';
import { WMSLayer } from '@simosol/layers-control';

const mwsLayers: WMSLayer[] = [
  {
    label: LangKey.MapBaseLayersLoisto,
    tileURL: '',
  },
  {
    label: LangKey.MapMwsLayersNoBgr,
    tileURL: '',
  },
  {
    label: LangKey.MapMwsLayersAerialFci,
    tileURL: 'https://ms.simosol.fi/mapproxy/service?service=wms&version=1.3.0&request=GetMap&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=1073&height=569&LAYERS=aerial_fci&STYLES=&FORMAT=image/jpeg',
    minZoom: 13,
  },
  {
    label: LangKey.MapMwsLayersChm,
    tileURL: 'https://ms.simosol.fi/mapproxy/service?service=wms&version=1.3.0&request=GetMap&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=1073&height=569&LAYERS=chm&STYLES=&FORMAT=image/png',
    minZoom: 13,
  },
  {
    label: LangKey.MapMwsLayersAerialTci,
    tileURL: 'https://ms.simosol.fi/mapproxy/service?service=wms&version=1.3.0&request=GetMap&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=1073&height=569&LAYERS=aerial_tci&STYLES=&FORMAT=image/jpeg',
    minZoom: 13,
  },
  {
    label: LangKey.MapMwsLayersDsm,
    tileURL: 'https://ms.simosol.fi/mapproxy/service?service=wms&version=1.3.0&request=GetMap&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=1073&height=569&LAYERS=dsm&STYLES=&FORMAT=image/png',
    minZoom: 13,
  },
  {
    label: LangKey.MapMwsLayersAnt,
    tileURL: 'https://ms.simosol.fi/mapproxy/service?service=wms&version=1.3.0&request=GetMap&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=1073&height=569&LAYERS=mapant&STYLES=&FORMAT=image/png',
    minZoom: 13,
  },
  {
    label: LangKey.MapMwsLayersHarvest,
    tileURL: 'https://aineistot.metsakeskus.fi/metsakeskus/services/Korjuukelpoisuus/Korjuukelpoisuus/MapServer/WmsServer?version=1.3.0&request=GetMap&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=1073&height=569&LAYERS=Korjuukelpoisuuskartat&STYLES=&FORMAT=image/png',
    minZoom: 13,
  },
];

export default mwsLayers;
