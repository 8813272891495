import { computed, makeObservable } from 'mobx';
import { appModel } from './App';
import { LangKey } from '../LangKey';
import { AppToolbarTabs } from '../views/app/appBar/AppToolbar';
import { Page } from '../Browser';
import { Project } from './Projects';
import { parse as wktParse } from 'wellknown';
import { Feature, GeoJsonObject, MultiPolygon, Polygon } from 'geojson';
import moment from 'moment';
import { DataNode, dataNodeOptions, DataNodePropComplex, DescriptorComplex } from './DataNodeUtils';
import proj4 from 'proj4';
import { StandPriority } from '@simosol/stands-map/lib/Stand';
import { DDataNode, DescriptorType } from '@simosol/iptim-data-model';
import DescriptorName from './DescriptorName';

export type StandGeoJson = GeoJsonObject & { properties: StandGeoJsonProperties };
export type StandGeoJsonProperties = { standId: string };

proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

/*

Every stand page has sub pages. Maximum number of sub pages is 5
Every sub page has 2-3 parts. Example of stand page, which has only one sub page:

Stand info
p1 = stand id
p2 = 'info'
--
Stand page operations list
p1 = stand id
p2 = 'prop'
p3 = 'operations'
--
Stand page 'table' (sizeClass):
p1 stand id
p2 = 'prop'
p3 = 'sizeClass'

First sub page is always stand page.
When user wants to see stand operation, one more sub page is added with same structure.
Example of stand operation 'harvest' page:

Harvest operation info
(sub page 1) p1 = stand id
(sub page 1) p2 = 'prop'
(sub page 1) p3 = 'operations'
(sub page 2) p4 = harvest operation id
(sub page 2) p5 = 'info'
--
Harvest operation notes list
(sub page 1) p1 = stand id
(sub page 1) p2 = 'prop'
(sub page 1) p3 = 'operations'
(sub page 2) p4 = harvest operation id
(sub page 2) p5 = 'prop'
(sub page 2) p6 = 'notes'

And so on. So general page structure is
p[N] = element id
p[N+1] = 'info' | 'prop'
P[N+2] = element prop key, if p[N+1] is 'prop'
where N = 1, 4, 7, ...
*/

export type StandPage = { p: 'stand' } & {[key: string]: string};

enum SubPageType {
  info = 'info',
  prop = 'prop',
}

type SubPage =
  { id: string, type: SubPageType.info } |
  { id: string, type: SubPageType.prop, key: string };
type ElementPageData =
  {
    element: DataNode,
    subPages: SubPage[],
  } & (
    { type: SubPageType.info } |
    { type: SubPageType.prop, prop: DataNodePropComplex }
  );

const MAX_SUB_PAGES = 5;

class StandTabs implements AppToolbarTabs {

  constructor() {
    makeObservable(this);
  }

  @computed
  get data() {
    const pageData = appModel.stands.currentPageData;
    if (!pageData) return [];
    const { element } = pageData;
    const propsElements = element.propsElements;
    if (propsElements.length > 0) {
      const data: { label: string, value: string }[] =
        [{ label: LangKey.StandInfo, value: SubPageType.info }];
      for (const propElements of propsElements) {
        if (propElements.key === DescriptorName.operations && appModel.isTapio) continue;
        data.push({ label: propElements.displayName, value: propElements.key });
      }
      return data;
    }
    return [];
  }

  @computed
  get value(): string | number {
    const pageData = appModel.stands.currentPageData;
    if (!pageData) return '';
    if (pageData.type === 'info') {
      return SubPageType.info;
    } else if (pageData.type === 'prop') {
      return pageData.prop.key;
    }
    return '';
  }

  onClick = (value: string | number) => {
    const pageData = appModel.stands.currentPageData;
    if (!pageData) return;
    const subPages: SubPage[] = pageData.subPages.concat();
    let newLastPage: SubPage;
    if (value === SubPageType.info) {
      newLastPage = { type: SubPageType.info, id: pageData.element.id };
    } else {
      newLastPage = { type: SubPageType.prop, id: pageData.element.id, key: value.toString() };
    }
    subPages.splice(subPages.length - 1, 1, newLastPage);
    setPageFromSubPages(subPages);

  }
}

export class Stands {
  private _pageBeforeStands?: Page = undefined;
  tabs = new StandTabs();

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentPageData(): ElementPageData | undefined {
    const pg = appModel.browser.page;
    let data: ElementPageData | undefined = undefined;
    for (let i = 0; i < MAX_SUB_PAGES; i += 1) {
      const sp = this._getSubPage(pg, i);
      if (!sp) {
        // return last data
        break;
      }
      let element: DataNode | undefined = undefined;
      if (i === 0) {
        element = appModel.projects.getStand(sp.id);
      } else if (data && data.type === 'prop') {
        element = data.prop.getElement(sp.id);
      }
      if (!element) {
        // there is wrong id, we should return nothing
        data = undefined;
        break;
      }
      const subPages: SubPage[] = data ? data.subPages : [];
      subPages.push(sp);
      if (sp.type === SubPageType.info) {
        data = { element, subPages, type: sp.type };
        // return this data
        break;
      } else {
        const prop = element.getProp(sp.key, DescriptorType.complex);
        if (!prop) break;
        data = { element, prop, subPages, type: sp.type };
      }
    }
    return data;
  }

  @computed
  get currentStand() {
    const pageData = appModel.stands.currentPageData;
    if (!pageData) return;
    const firstPage = pageData.subPages.length > 0 ? pageData.subPages[0] : undefined;
    if (!firstPage) return;
    return appModel.projects.getStand(firstPage.id);
  }

  private _getSubPage = (pg: Page, subPageIndex: number): SubPage | undefined => {
    if (pg.p !== 'stand') return undefined;
    const n = subPageIndex * 3 + 1;
    const id: string | undefined = pg['p' + n];
    const type: string | undefined = pg['p' + (n + 1)];
    const key: string | undefined = pg['p' + (n + 2)];
    if (!id) return undefined;
    if (type === SubPageType.info) {
      if (key) return undefined;
      return { id, type };
    } else if (type === SubPageType.prop) {
      if (!key) return undefined;
      return { id, key, type };
    }
    return undefined;
  }

  showStand = (stand: DataNode) => {
    this._pageBeforeStands = appModel.browser.page;
    const subPages: SubPage[] = [{ id: stand.id, type: SubPageType.info }];
    setPageFromSubPages(subPages);
  }

  onElementClick = (element: DataNode) => {
    const pageData = appModel.stands.currentPageData;
    if (!pageData) return;
    const subPages: SubPage[] = pageData.subPages.concat();
    subPages.push({ id: element.id, type: SubPageType.info });
    setPageFromSubPages(subPages);
  }

  previousStandPage = () => {
    const pageData = appModel.stands.currentPageData;
    if (!pageData) return;
    const subPages: SubPage[] = pageData.subPages.concat();
    if (subPages.length > 1) {
      subPages.pop();
      setPageFromSubPages(subPages);
    } else {
      this.back();
    }
  }

  back = () => {
    if (this._pageBeforeStands) {
      appModel.browser.page = this._pageBeforeStands;
      this._pageBeforeStands = undefined;
    } else {
      const stand = this.currentStand;
      if (stand) {
        const project = stand.project;
        if (project) {
          appModel.projects.showProject(project);
          return;
        }
      }
      appModel.browser.page = { p: '' };
    }
  }
}

export class Stand extends DataNode {
  private readonly _project: Project;
  private readonly _assignedToMe: boolean;

  constructor(project: Project, data: DDataNode, descriptor: DescriptorComplex) {
    super(data, descriptor, undefined, dataNodeOptions);
    makeObservable(this);
    this._project = project;
    this._assignedToMe = this.isAssignedToMe();
  }

  get area(): number {
    const area = this.getValue('area', DescriptorType.numeric);
    if (area) return area;
    return 0;
  }
  get propArea() {
    return this.getProp('area', DescriptorType.numeric);
  }

  get plantingYear() {
    const prop = this.getProp('plantingDate', DescriptorType.date);
    if (prop) {
      const date = moment(prop.value);
      return date.format('YYYY');
    }
    return undefined;
  }

  get species() {
    const prop = this.getProp('species', DescriptorType.category);
    if (prop) {
      return prop.displayValue;
    }
    return undefined;
  }

  get municipality() {
    const prop = this.getProp('municipality', DescriptorType.category);
    if (prop) {
      return prop.displayValue;
    }
    return undefined;
  }

  get name() {
    let label: string = this.listPrimaryPropDisplayValue ?? '';
    const subId = this.getProp('subId', DescriptorType.text)?.value
      || this.getProp('extension', DescriptorType.text)?.value;
    if (subId) label += `.${subId}`;
    return label;
  }

  @computed
  get priority(): StandPriority {
    const currentYear = new Date().getUTCFullYear();
    const opYear = this.yearOfFirstOperation;
    if (!opYear) return StandPriority.low;
    if (opYear <= currentYear) return StandPriority.urgent;
    if (opYear === currentYear + 1) return StandPriority.high;
    if (opYear >= currentYear + 2 && opYear <= currentYear + 9) return StandPriority.normal;
    return StandPriority.low;
  }

  @computed
  get yearOfFirstOperation() {
    if (!this.hasOperation) return;
    return moment(this.propOperations!.nodes[0].listSecondaryPropDisplayValue).year();
  }

  get ESPG() {
    return appModel.isTapio ? 'EPSG:3067' : 'EPSG:3857';
  }

  @computed
  get geoJSONToMap(): Feature<Polygon | MultiPolygon> | undefined {
    const geomProp = this.getProp('geom', DescriptorType.geo)?.value ?? this.data.geom as { wkt: string };
    if (!geomProp?.wkt) return;
    const polygon = wktParse(geomProp.wkt) as Polygon | MultiPolygon;

    polygon.type === 'Polygon'
      ? polygon.coordinates = polygon.coordinates.map(positionArr =>
          positionArr.map(position =>
            proj4(this.ESPG).inverse(position)))
      : polygon.coordinates = polygon.coordinates.map(positionArr =>
          positionArr.map(position =>
            position.map(pos =>
              proj4(this.ESPG).inverse(pos))));

    return  {
      type: 'Feature',
      geometry: polygon,
      properties: {},
    };
  }

  @computed
  get propOperations() {
    return this.getProp(DescriptorName.operations, DescriptorType.complex);
  }

  get hasOperation() {
    return !!this.propOperations?.nodes.length;
  }

  @computed
  get areaWithUnit(): string {
    if (!this.propArea) return '';
    return `${this.propArea.value?.toFixed(1) ?? this.propArea.displayValue} ${LangKey.UnitsHa.t()}`;
  }

  get project() {
    return this._project;
  }
  get assignedToMe() {
    return this._assignedToMe;
  }

  isAssignedToMe(): boolean {
    const operations = this.propOperations;
    if (!operations) return false;
    const userId = localStorage.getItem('userId');
    if (!userId) return false;
    for (const operation of operations.nodes) {
      if (operation.data.assignee?.toString() === userId) return true;
    }
    return false;
  }
}

const setPageFromSubPages = (subPages: SubPage[]) => {
  const newP: StandPage = { p: 'stand' };
  for (let i = 0; i < subPages.length; i += 1) {
    const n = i * 3 + 1;
    const sp = subPages[i];
    newP['p' + n] = sp.id;
    newP['p' + (n + 1)] = subPages[i].type;
    if (sp.type === SubPageType.info) {
      break;
    } else {
      newP['p' + (n + 2)] = sp.key;
    }
  }
  appModel.browser.page = newP;
};
