import * as React from 'react';
import { appModel } from '../models/App';
import { observer } from 'mobx-react';
import CircularProgress from '@mui/material/CircularProgress';
import grey from '@mui/material/colors/grey';

@observer
class DataPreloader extends React.Component {
  render() {
    if (!appModel.dataInitialized) {
      return <CircularProgress style={{ margin: 32, color: grey[500] }}/>;
    } else {
      return this.props.children || null;
    }
  }
}

export default DataPreloader;
