import { action, computed, makeObservable, observable } from 'mobx';
import { appModel } from './App';
import { DDataNode, DescriptorType } from '@simosol/iptim-data-model';
import { DataNode, dataNodeOptions, DescriptorComplex, propsDisplayFiltered } from './DataNodeUtils';
import { Feature, LineString, MultiPolygon, Point, Polygon } from 'geojson';
import { parse as wktParse } from 'wellknown';
import proj4 from 'proj4';

export type DFreeGeometries = {
  data: [DFreeGeometry[], number],
  structure: DescriptorComplex,
};

export type DFreeGeometry = {
  id: number,
  name: string,
  freeGeometry: {
    wkt: string,
  },
  operationAssignee?: number,
  operationAssigneeWorkTeam?: number,
  operationStatus?: number,
  operationType?: number,
  operationDateExecuted?: string,
  operationDateStart?: string,
  operationPlannedYear?: string,
  workSiteType: number,
  additionalInfo: string,
  contactPerson: string,
  executionDetails: string,
} & DDataNode;

export type DNewFG = {
  id: number;
  data: {
    operationAssignee: string | undefined;
    operationDateExecuted?: string | null;
    operationDateStart?: string | null;
    operationPlannedYear: number | undefined;
    operationStatus: number | undefined;
    operationType: number | undefined;
  }
};

enum FGProps {
  operationAssignee = 'operationAssignee',
  operationDateExecuted = 'operationDateExecuted',
  operationDateStart = 'operationDateStart',
  operationPlannedYear = 'operationPlannedYear',
  operationStatus = 'operationStatus',
  operationType = 'operationType',
}

export default class FreeGeometries {
  @observable.ref
  private _data!: DFreeGeometries;
  @observable.shallow
  freeGeometries: FreeGeometry[] = [];
  @observable
  onlyAssigned: boolean = true;

  constructor() {
    makeObservable(this);
  }

  get data() { return this._data; }
  @action
  setData = (fgs: DFreeGeometries) => {
    this._data = fgs;
    this.freeGeometries = fgs.data[0].map(fg => new FreeGeometry(fg, fgs.structure));
  }

  @computed
  get version() {
    let sum = 0;
    this.freeGeometries.forEach((fg) => {
      sum += fg.dataNode.version;
    });
    return sum;
  }

  commit = () => {
    this.freeGeometries.forEach(fg => fg.dataNode.commit());
  }

  get structure() {
    return this._data.structure;
  }

  getUnsavedFGs = (): DNewFG[] => {
    const arr: DNewFG[] = [];
    for (const freeGeometry of this.freeGeometries) {
      const uFreeGeometry = freeGeometry.getUnsavedFG();
      if (uFreeGeometry !== undefined) arr.push(uFreeGeometry);
    }
    return arr;
  }

  @computed
  get currentFG() {
    const pg = appModel.browser.page;
    if (pg.p === 'freeGeometry') {
      const fg = this.get(pg.p1);
      if (fg) return fg;
    }
    return undefined;
  }

  get = (fgId: string) => {
    return this.freeGeometries.find(fg => fg.id === +fgId);
  }
}

export class FreeGeometry {
  dataNode: DataNode;

  private readonly _data: DFreeGeometry;

  constructor(data: DFreeGeometry, descriptor: DescriptorComplex) {
    this._data = data;
    this.dataNode = new DataNode(data, descriptor, undefined, dataNodeOptions);
    makeObservable(this);
  }

  getUnsavedFG = (): DNewFG | undefined => {
    if (!this.dataNode.version) return undefined;
    return {
      id: this.id,
      data: {
        operationAssignee: this.operationAssignee,
        operationDateExecuted: this.operationDateExecuted,
        operationDateStart: this.operationDateStart,
        operationPlannedYear: this.operationPlannedYear,
        operationStatus: this.operationStatus,
        operationType: this.operationType,
      },
    };
  }

  get id() {
    return this._data.id;
  }
  get name() {
    return this._data.name;
  }

  @computed
  get operationAssignee(): string | undefined {
    const prop = this.dataNode.getProp('operationAssignee', DescriptorType.dynamicCategory);
    return prop ? prop.value : undefined;
  }
  @computed
  get operationAssigneeName(): string | undefined {
    const prop = this.dataNode.getProp('operationAssignee', DescriptorType.dynamicCategory);
    return prop ? prop.displayValue : undefined;
  }
  @computed
  get operationDateExecuted(): string | null | undefined {
    const date =  this.dataNode.getProp(FGProps.operationDateExecuted, DescriptorType.date);
    if (date) {
      return date.value ? date.displayValue : date.value;
    }
    return null;
  }
  @computed
  get operationDateStart(): string | undefined  {
    const date =  this.dataNode.getProp(FGProps.operationDateStart, DescriptorType.date);
    if (date) {
      return date.value ? date.displayValue : date.value;
    }
    return undefined;
  }
  @computed
  get operationPlannedYear(): number | undefined {
    return this.dataNode.getValue(FGProps.operationPlannedYear, DescriptorType.numeric);
  }
  @computed
  get operationStatus(): number | undefined {
    const prop = this.dataNode.getProp(FGProps.operationStatus, DescriptorType.category);
    return prop ? prop.value : undefined;
  }
  @computed
  get operationType(): number | undefined {
    const type = this.dataNode.getProp(FGProps.operationType, DescriptorType.category);
    return type ? type.value : undefined;
  }
  get contactPerson() { return this._data.contactPerson; }
  get executionDetails() { return this._data.executionDetails; }
  get plannedYear() { return this._data.operationPlannedYear; }

  @computed
  get props() {
    const propKeys: string[] = ['id'];
    return propsDisplayFiltered(this.dataNode.props).filter(
      prop => propKeys.indexOf(prop.key) === -1,
    );
  }

  @computed
  get geoJSONToMap(): Feature<LineString | Point |Polygon | MultiPolygon> | undefined {
    const geomProp = this._data.freeGeometry as { wkt: string };
    if (!geomProp?.wkt) return;
    const geometry = wktParse(geomProp.wkt) as LineString | Point |Polygon | MultiPolygon;
    switch (geometry.type) {
      case 'Point': {
        geometry.coordinates = proj4('EPSG:3067').inverse(geometry.coordinates);
        break;
      }
      case 'LineString': {
        geometry.coordinates = geometry.coordinates.map(positionArr => proj4('EPSG:3067').inverse(positionArr));
        break;
      }
      case 'Polygon': {
        geometry.coordinates = geometry.coordinates.map(positionArr =>
          positionArr.map(position => proj4('EPSG:3067').inverse(position)));
        break;
      }
      case 'MultiPolygon': {
        geometry.coordinates = geometry.coordinates.map(positionArr =>
          positionArr.map(position =>  position.map(pos => proj4('EPSG:3067').inverse(pos))));
        break;
      }
    }

    return  {
      geometry,
      type: 'Feature',
      properties: {},
    };
  }

  isAssignedToMe(): boolean {
    const userId = localStorage.getItem('userId');
    if (!userId) return false;
    return this._data.operationAssignee === +userId;
  }
}
