import EntityCache from './EntityCache';
import { computed, makeObservable, observable } from 'mobx';
import { appModel } from './App';
import {
  ChangedData, DDataNode,
} from '@simosol/iptim-data-model';
import { Stand } from './Stands';
import arraySearch from '../arraySearch';
import { DataNode, DescriptorComplex } from './DataNodeUtils';
import DescriptorName from './DescriptorName';

export interface DPartialProject {
  uid: string;
  name: string;
  data: DDataNode[];
  structure?: DescriptorComplex;
}

export interface DProject extends DPartialProject {
  structure: DescriptorComplex;
}

export class Project {
  @observable.ref
  private _data!: DProject;
  @observable.shallow
  stands: Stand[] = [];

  private readonly _id: string;
  private readonly _name: string;

  constructor(data: DProject) {
    makeObservable(this);
    this._id = data.uid;
    this._name = data.name;
    // noinspection JSIgnoredPromiseFromCall
    this.setData(data);
  }

  @computed
  get standsCreationProgress() {
    const standsTotal = this.standsTotal;
    if (standsTotal === undefined) return 0;
    const standsCreated = this.standsCreated;
    return standsCreated < standsTotal ? standsCreated / standsTotal : 1;
  }

  @computed
  get standsTotal(): number | undefined {
    if (!this._data) return undefined;
    return this._data.data.length;
  }
  @computed
  get standsCreated() {
    return this.stands.length;
  }

  @computed
  get allStandsCreated() {
    return this.standsCreationProgress === 1;
  }

  get id() { return this._id; }
  get name() { return this._name; }
  setData = async (data: DProject) => {
    this.stands = [];
    this._data = data;
  }

  createStands = async () => {
    const data = this._data;
    const structure = data.structure as unknown as DescriptorComplex;

    // TODO: Адаптация данных с КИТа
    if (Array.isArray(structure.properties)) {
      const tr = (structure.properties as [])
        .find(p => p['name'] === DescriptorName.treatments) as unknown as DescriptorComplex;
      if (tr) {
        const properties = structure.properties;
        const op = (tr.properties as [])
          .find(o => o['name'] === DescriptorName.operation) as unknown as DescriptorComplex;
        const { name, ...props } = op;
        // @ts-ignore
        properties.push({ name: DescriptorName.operations as const, ...props });
        structure.properties = properties;
      }
    }
    const len = data.data.length;
    for (let i = 0; i < len; i += 1) {
      const standData = data.data[i];
      await this._addStandData(standData, structure);
    }
  }

  private _addStandData = (standData: DDataNode, structure: DescriptorComplex): Promise<DDataNode> => {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        this.stands.push(new Stand(this, standData, structure));
        resolve(standData);
      });
    });
  }

  getStand = (standId: string) => {
    return arraySearch('id', standId, this.stands);
  }

  get data() { return this._data; }

  @computed
  get standsCount() {
    return this.stands.length;
  }

  get standsMunicipality() {
    return [...new Set(this.stands.map(stand => stand.municipality))];
  }

  @computed
  get area() {
    let area = 0;
    this.stands.forEach((stand) => {
      area += stand.area;
    });
    return Math.round(area);
  }

  @computed
  get version() {
    let sum = 0;
    this.stands.forEach((stand: DataNode) => {
      sum += stand.version;
    });
    return sum;
  }

  @computed
  get changedData(): ChangedData[]  {
    let res: ChangedData[] = [];
    for (const stand of this.stands) {
      res = res.concat(stand.changedData);
    }
    return res;
  }

  commit = () => {
    this.stands.forEach(stand => stand.commit());
  }
}

export type ProjectsChangedData = {[key: string]: ChangedData[]};

export default class Projects extends EntityCache<Project, DProject> {
  constructor() {
    super(
      data => new Project(data),
      data => data.uid,
      (project: Project, data: DProject) => project.setData(data),
    );
    makeObservable(this);
  }

  @computed
  get version() {
    let sum = 0;
    this.forEach((project) => {
      sum += project.version;
    });
    return sum;
  }

  @computed
  get changedData(): ProjectsChangedData {
    const res: ProjectsChangedData = {};
    this.forEach((project) => {
      const projectChangedData = project.changedData;
      if (projectChangedData.length === 0) return;
      res[project.id] = project.changedData;
    });
    return res;
  }

  getStand = (standId: string): Stand | undefined => {
    let stand: Stand | undefined;
    this.forEach(((project) => {
      if (!stand) stand = project.getStand(standId);
    }));
    return stand;
  }

  showProject = (project: Project) => {
    appModel.browser.page = { p: 'project', p1: project.id };
  }

  commit = () => {
    this.forEach(project => project.commit());
  }

  createStands = async () => {
    const projects = this.map(project => project);
    for (const project of projects) {
      await project.createStands();
    }
  }

  @computed
  get standsCreated(): number {
    return this.reduce((total, project) => total + project.standsCreated, 0);
  }

  @computed
  get standsTotal(): number | undefined {
    if (this.length === 0) return undefined;
    let total: number | undefined = 0;
    this.forEach((project) => {
      if (total === undefined) return;
      const projectTotal = project.standsTotal;
      total = projectTotal !== undefined ? total + projectTotal : undefined;
    });
    return total;
  }
}
