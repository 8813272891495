import * as React from 'react';
import CenterContainer from '../../uicomponents/CenterContainer';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  progress: {
    width: '100%',
    maxWidth: 200,
    height: theme.spacing(3),
  },
}));

const AppPreloader = (props: { progressPercent: number, message?: string }) => {
  const classes = useStyles();
  return (
    <CenterContainer>
      <LinearProgress className={classes.progress} variant={'determinate'} value={props.progressPercent}/>
    </CenterContainer>
  );
};

export default AppPreloader;
