import * as React from 'react';
import { observer } from 'mobx-react';

import { appModel, UserRole } from '../../models/App';
import Confirm from '../../uicomponents/Confirm';
import { Page } from '../../Browser';
import { LangKey } from '../../LangKey';
import ListItemText from '../../uicomponents/ListItemText';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import { SvgIconProps } from '@mui/material/SvgIcon';
import List from '@mui/icons-material/List';
import Assignment from '@mui/icons-material/Assignment';
import Settings from '@mui/icons-material/Settings';
import FilterTiltShift from '@mui/icons-material/FilterTiltShift';

type MainMenuButtonProps = {
  selected: boolean;
  clickPage: Page;
  label: string;
  icon: React.ReactElement<SvgIconProps>;
};

class MainMenuButton extends React.Component<MainMenuButtonProps> {
  private _onClick = () => {
    appModel.drawerOpen = false;
    appModel.browser.page = this.props.clickPage;
  }
  render() {
    const { selected, label, icon } = this.props;
    return (
      <ListItem
        button
        selected={selected}
        onClick={this._onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItem>
    );
  }
}

interface MainMenuProps {
  page: Page;
}

@observer
class MainMenu extends React.Component<MainMenuProps> {
  render() {
    const { page } = this.props;
    const p = page.p;
    const userRole = appModel.userRole;
    return (
      <>
        {(userRole === UserRole.manager || userRole === UserRole.auditor) &&
        <MainMenuButton
          selected={p === 'projects' || p === ''}
          clickPage={{ p: 'projects' }}
          label={LangKey.ProjectsTitle}
          icon={<List /> as React.ReactElement<SvgIconProps>}
        />
        }
        <MainMenuButton
          selected={p === 'tasks' || (p === '' && userRole === UserRole.assignee)}
          clickPage={{ p: 'tasks' }}
          label={LangKey.TasksTitle}
          icon={<Assignment/> as React.ReactElement<SvgIconProps>}
        />
        {appModel.isTapio && (
          <MainMenuButton
            selected={p === 'freeGeometries'}
            clickPage={{ p: 'freeGeometries' }}
            label={LangKey.FreeGeometryTitle}
            icon={<FilterTiltShift /> as React.ReactElement<SvgIconProps>}
          />
        )}
        <MainMenuButton
          selected={p === 'settings'}
          clickPage={{ p: 'settings' }}
          label={LangKey.SettingsTitle}
          icon={<Settings /> as React.ReactElement<SvgIconProps>}
        />
        <Confirm
          message={LangKey.AuthLogoutConfirm.t()}
          variant={'listItem'}
          onClick={appModel.logout}
          icon={<PowerSettingsNew/>}
        >
          {LangKey.AuthLogoutButton.t()}
        </Confirm>
      </>
    );
  }
}

export default MainMenu;
