import * as React from 'react';
import {
  MapButton,
  Stand as MapStand,
  usePositionWatcher,
  usePositionLock,
  useOwnLocationMarker,
  OwnLocationButton,
  useBaseLayer,
  useMap,
  ZoomButtons,
  BaseLayerButton,
  useMapDefaultStyles,
  useStandClick,
  useMapFitBoundsAndSave,
  GpsErrorDialog,
  useOptimizedStandLayers,
} from '@simosol/stands-map';

import { appModel } from '../../models/App';
import Close from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import { Project } from '../../models/Projects';
import { Stand } from '../../models/Stands';
import ZoomToSelectedStands from './ZoomToSelectedStands';
import baseLayers from './baseLayers';
import baseLayersETapio from './etapio/baseLayersETapio';
import vectorLayers from './vectorLayers';
import mwsLayers from './mwsLayers';
import { LangKey } from '../../LangKey';
import { FreeGeometry } from '../../models/FreeGeometries';
import useFreeGeometries from './etapio/useFreeGeometries';
import { RasterAndVectorLayersControl } from '@simosol/layers-control';

const useStyles = makeStyles((theme: Theme) => createStyles({
  closeButton: {
    position: 'absolute',
    width: theme.spacing(6),
    height: theme.spacing(6),
    left: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1.5),
    cursor: 'pointer',
  },
  buttonLocation: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(10),
    borderRadius: theme.spacing(3),
  },
}));

type Props = {
  mapStands: MapStand[],
  onChange: (stand?: MapStand) => void,
  currentProject?: Project,
  currentStand?: Stand,
  mapGeometry?: FreeGeometry,
};

const ProjectStandsMap = (props: Props) => {
  const { mapStands, currentProject, onChange, currentStand, mapGeometry } = props;

  const styles = useStyles();
  const classes = useMapDefaultStyles();

  for (const layer in baseLayers) {
    baseLayers[layer].name = baseLayers[layer].name.t();
  }
  for (const layer in mwsLayers) {
    mwsLayers[layer].label = mwsLayers[layer].label.t();
  }
  for (const layer in vectorLayers) {
    vectorLayers[layer].label = vectorLayers[layer].label.t();
  }

  const { map, mapContainer } = useMap(
    classes.mapContainer,
    {
      container: 'map',
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      zoom: 15,
      center: [23.7278, 61.4951],
      dbName: 'mapboxIDB',
      dbStore: 'tiles',
    },
  );
  const {
    baseLayer,
    setBaseLayer,
    baseLayerChangedNum,
  } = useBaseLayer(map, appModel.isTapio ? baseLayersETapio : baseLayers);

  useOptimizedStandLayers(map, mapStands, baseLayerChangedNum, { zoomFilter: 15 });
  useStandClick(map, onChange, baseLayerChangedNum);

  /** GPS **/
  const positionWatcher = usePositionWatcher();
  const [positionLock, setPositionLock] = usePositionLock(map, positionWatcher);
  useOwnLocationMarker(map, positionWatcher, classes.locationMarker);
  useMapFitBoundsAndSave(map, mapStands, `iptim_mobile_map_bounds_${currentProject?.name}`, !positionLock);
  useFreeGeometries({ map, mapGeometry, baseLayerChangedNum });

  return (
    <>
      {mapContainer}
      {appModel.isTapio ?
        <RasterAndVectorLayersControl
          map={map}
          vectorLayersTitle={LangKey.MapVectorLayersTitle.t()}
          vectorLayers={vectorLayers}
          wmsLayersTitle={LangKey.MapMwsLayersTitle.t()}
          wmsLayers={mwsLayers}
          baseLayerChangedNum={baseLayerChangedNum}
        /> :
        <BaseLayerButton
          className={classes.buttonLayers}
          allLayers={baseLayers}
          baseLayer={baseLayer}
          setBaseLayer={setBaseLayer}
        />
      }
      <ZoomToSelectedStands
        map={map}
        stands={mapStands}
        currentStand={currentStand}
        mapGeometry={mapGeometry}
      />
      <OwnLocationButton
        className={styles.buttonLocation}
        setPositionLock={setPositionLock}
        positionWatcher={positionWatcher}
        positionLock={positionLock}
      />
      <ZoomButtons
        map={map}
        className={classes.buttonsZoom}
      />
      <GpsErrorDialog
        positionWatcher={positionWatcher}
      />
      <MapButton className={styles.closeButton}>
        <Close onClick={() => appModel.map.back()}/>
      </MapButton>
    </>
  );
};

export default ProjectStandsMap;
