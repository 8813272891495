import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LangKey } from '../../../LangKey';
import Button from '../../../uicomponents/Button';
import { DataNodePropCategory, DataNodePropDynamicCategory } from '../../../models/DataNodeUtils';

type Item = DataNodePropCategory | DataNodePropDynamicCategory;

type CategoryPropEditorProps = {
  item: Item;
  open: boolean;
  onClose: () => void;
};

const unsetValue = '__unset__';

const EditorCategory = (props: CategoryPropEditorProps) => {
  const { open, onClose, item } = props;

  const formValue = (item: Item) => item.value !== undefined && item.value !== null
    ? item.value.toString()
    : unsetValue;

  const [value, setValue] = React.useState<string>(
    () => formValue(item),
  );

  const cancel = () => {
    setValue(formValue(item));
    onClose();
  };

  const save = () => {
    item.value = (value === unsetValue)
      ? item.value = undefined
      : Number(value);
    onClose();
  };

  const options: { value: string | number, label: string }[] =
    React.useMemo(() => item.getOptions(), [item]);

  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={cancel}
    >
      <DialogTitle>{item.displayName}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          value={value}
          onChange={onOptionChange}
        >
          {options.map(option => (
            <FormControlLabel
              value={option.value.toString()}
              key={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{LangKey.CommonCancel}</Button>
        <Button onClick={save}>{LangKey.CommonOk}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditorCategory;
