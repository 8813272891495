import * as defaultRules from '@simosol/forms/lib/rules';
import { LangKey } from '../../LangKey';
export const required = () => defaultRules.required(LangKey.FormsErrorRequired.t());
export const number = () => defaultRules.number(LangKey.FormsErrorNumber.t());
export const min = (valueMin: number) => (value: any) => {
  const valueIsNumber = defaultRules.number()(value) === undefined;
  if (valueIsNumber && Number(value) < valueMin) return LangKey.FormsErrorMin.t({ value: valueMin });
  return undefined;
};

export const max = (valueMax: number) => (value: any) => {
  const valueIsNumber = defaultRules.number()(value) === undefined;
  if (valueIsNumber && Number(value) > valueMax) return LangKey.FormsErrorMax.t({ value: valueMax });
  return undefined;
};
