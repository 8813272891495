import * as React from 'react';
import ListItemText from '../../uicomponents/ListItemText';
import Text from '../../uicomponents/Text';
import { Stand } from '../../models/Stands';
import ListItem from '@mui/material/ListItem';

type StandListItemProps = {
  onStandClick: () => void;
  stand: Stand;
};

const getAreaText = (stand: Stand) => {
  const area = stand.propArea;
  if (area) {
    return stand.areaWithUnit;
  }
  return '';
};

const StandListItem = (props: StandListItemProps) => {
  const { onStandClick, stand } = props;
  return (
    <ListItem button onClick={onStandClick} key={stand.id}>
      <ListItemText primary={stand.name} />
      <Text>{getAreaText(stand)}</Text>
    </ListItem>
  );
};

export default StandListItem;
