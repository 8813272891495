import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { HBox } from '../../uicomponents/Boxes';
import Text from '../../uicomponents/Text';
import { default as classNames } from 'classnames';
import { LangKey } from '../../LangKey';
import ButtonBase from '@mui/material/ButtonBase';
import makeStyles from '@mui/styles/makeStyles';
import EditorNumberString from './propEditor/EditorNumberString';
import EditorDate from './propEditor/EditorDate';
import EditorCategory from './propEditor/EditorCategory';
import {
  DataNodePropNumberWrapper,
  dataNodePropType,
  DataNodePropWrapper,
  DataNodeProp as MDynamicElementProp, propEditable,
} from '../../models/DataNodeUtils';
import { DescriptorType } from '@simosol/iptim-data-model';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minHeight: 48,
    padding: '0 12px',
    backgroundColor: '#FCFCFC',
    boxSizing: 'content-box',
  },
  itemLeft: {
    width: '50%',
    paddingRight: 8,
    boxSizing: 'border-box',
  },
  itemRight: {
    width: '50%',
    paddingLeft: 8,
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  itemRightEditable: {
    justifyContent: 'start',
  },
  textRightEditable: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    color: theme.palette.primary[500],
  },
  textLeft: {
    color: 'rgba(0,0,0,0.5)',
    fontWeight: 500,
  },
  textRight: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type DynamicElementPropsItemProps = {
  className?: string;
  item: MDynamicElementProp;
};

const DynamicElementProp = (props: DynamicElementPropsItemProps) => {
  const editOpened = React.useRef(false);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const onEditClick = () => {
    editOpened.current = true;
    setEditOpen(true);
  };

  const editStop = () => {
    setEditOpen(false);
  };

  const { item, className } = props;

  const itemWrapper = React.useMemo(
    () => (dataNodePropType(item, DescriptorType.numeric)
      ? new DataNodePropNumberWrapper(item)
      : new DataNodePropWrapper(item)),
    [item],
  );

  const editable = propEditable(item);
  let editor = null;

  if (editOpened.current) {
    if (dataNodePropType(item, DescriptorType.numeric) || dataNodePropType(item, DescriptorType.text)) {
      editor = <EditorNumberString open={editOpen} onClose={editStop} item={itemWrapper}/>;
    } else if (dataNodePropType(item, DescriptorType.date)) {
      editor = <EditorDate open={editOpen} onClose={editStop} item={item} />;
    } else if (dataNodePropType(item, DescriptorType.category)
        || dataNodePropType(item, DescriptorType.dynamicCategory)
    ) {
      editor = <EditorCategory item={item} open={editOpen} onClose={editStop} />;
    }
  }

  const displayValue = itemWrapper.displayValue;

  return (
    <HBox
      className={classNames(classes.root, className)}
      gap={0}
      align={'center'}
    >
      <div className={classes.itemLeft}>
        <Text disableTranslation className={classes.textLeft}>
          {itemWrapper.displayName}
        </Text>
      </div>
      {editable
      ?
        <>
          <ButtonBase
            onClick={onEditClick}
            className={classNames(classes.itemRight, classes.itemRightEditable)}
          >
            <Text
              className={classNames(classes.textRight, classes.textRightEditable)}
              disableTranslation
            >
              {displayValue ?? LangKey.DynamicPropertyAddValue.t()}
            </Text>
          </ButtonBase>
          {editor}
        </>
      :
        <div className={classes.itemRight}>
          <Text className={classes.textRight} disableTranslation >
            {displayValue}
          </Text>
        </div>
      }

    </HBox>
  );
};

export default DynamicElementProp;
