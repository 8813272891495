import * as React from 'react';
import * as turf from '@turf/turf';
import { appModel } from '../../../models/App';
import { FreeGeometry } from '../../../models/FreeGeometries';

const WORK_SITE_ALL_POINT = 'work_site_all_points';
const WORK_SITE_ALL_LINES = 'work_site_all_lines';
const WORK_SITE_ALL_POLYGONS = 'work_site_all_polygons';

const useFreeGeometries = (prop: {
  map: mapboxgl.Map | undefined
  mapGeometry?: FreeGeometry;
  baseLayerChangedNum: number;
}) => {
  const { map, mapGeometry, baseLayerChangedNum } = prop;

  React.useEffect(
    () => {
      const freeGeometries = !appModel.freeGeometries.onlyAssigned
        ? appModel.freeGeometries.freeGeometries
        : appModel.freeGeometries.freeGeometries.filter(fg => fg.isAssignedToMe());
      if (!map || !baseLayerChangedNum || !freeGeometries.length) return;
      const points = freeGeometries.filter((ws) => {
        return ws.geoJSONToMap?.geometry.type === 'Point';
      });
      const lines = freeGeometries.filter((ws) => {
        return ws.geoJSONToMap?.geometry.type === 'LineString';
      });
      const polygon = freeGeometries.filter((ws) => {
        return ws.geoJSONToMap?.geometry.type === 'Polygon';
      });

      if (mapGeometry) {
        map.fitBounds(
          turf.bbox(mapGeometry.geoJSONToMap) as [number, number, number, number],
          { padding: 20, linear: false, maxZoom: 18 },
        );
      }

      map.addLayer({
        id: WORK_SITE_ALL_POLYGONS,
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: polygon.map((el) => {
              const newGeoJSON: any = { ...el.geoJSONToMap };
              newGeoJSON.id = el.id;
              return newGeoJSON;
            }),
          },
        },
        layout: {},
        paint: {
          'fill-color': '#fa2a2a',
          'fill-opacity': 0.4,
        },
      });
      map.addLayer({
        id: WORK_SITE_ALL_LINES,
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: lines.map((el) => {
              const newGeoJSON: any = { ...el.geoJSONToMap };
              newGeoJSON.id = el.id;
              return newGeoJSON;
            }),
          },
        },
        layout: {
          'line-cap': 'butt',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#fa2a2a',
          'line-width': 10,
        },
      });
      map.addLayer({
        id: WORK_SITE_ALL_POINT,
        type: 'circle',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: points.map((el) => {
              const newGeoJSON: any = { ...el.geoJSONToMap };
              newGeoJSON.id = el.id;
              return newGeoJSON;
            }),
          },
        },
        paint: {
          'circle-radius': 10,
          'circle-color': '#fa2a2a',
        },
      });

      const clearLayer = (name: string) => {
        if (map.getLayer(name)) { map.removeLayer(name); }
        if (map.getSource(name)) { map.removeSource(name); }
      };

      return () => {
        clearLayer(WORK_SITE_ALL_POINT);
        clearLayer(WORK_SITE_ALL_LINES);
        clearLayer(WORK_SITE_ALL_POLYGONS);
      };
    },
    [map, baseLayerChangedNum, mapGeometry],
  );
};

export default useFreeGeometries;
