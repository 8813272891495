import * as React from 'react';
import { isCordova } from '../../index';
import Text from '../../uicomponents/Text';
import CenterContainer from '../../uicomponents/CenterContainer';
import { VBox } from '../../uicomponents/Boxes';
import { LangKey } from '../../LangKey';
import CircularProgress from '@mui/material/CircularProgress';

interface UpdateDialogProps {}

interface UpdateDialogState {
  status?: SyncStatus;
}

class UpdateDialog extends React.Component<UpdateDialogProps, UpdateDialogState> {
  constructor(props: UpdateDialogProps) {
    super(props);
    this.state = {};
  }

  private _onCodePushSync = (status?: SyncStatus) => {
    if (status === undefined) return;
    this.setState({ status });
  }

  componentDidMount() {
    if (window.codePush) {
      window.codePush.sync(
        this._onCodePushSync,
        { installMode: InstallMode.IMMEDIATE },
      );
    }
  }

  render() {
    if (!isCordova) {
      return this.props.children;
    }
    if (this.state.status === SyncStatus.UP_TO_DATE || this.state.status === SyncStatus.ERROR) {
      return this.props.children;
    }
    console.log('test');
    let msg = '';
    switch (this.state.status) {
      case SyncStatus.CHECKING_FOR_UPDATE:
        msg = LangKey.UpdateDialogChecking;
        break;
      case SyncStatus.DOWNLOADING_PACKAGE:
        msg = LangKey.UpdateDialogDownloading;
        break;
      case SyncStatus.INSTALLING_UPDATE:
        msg = LangKey.UpdateDialogInstalling;
        break;
      case SyncStatus.UPDATE_INSTALLED:
        msg = LangKey.UpdateDialogInstalled;
        break;
    }
    return (
      <CenterContainer>
        <VBox gap={24} align={'center'}>
          <div>
            <Text variant={'subtitle1'}>{msg}</Text>
          </div>
          <CircularProgress/>
        </VBox>
      </CenterContainer>
    );
  }
}

export default UpdateDialog;
