import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { appModel } from '../../models/App';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Text from '../../uicomponents/Text';
import { VBox } from '../../uicomponents/Boxes';
import Button from '../../uicomponents/Button';
import { LangKey } from '../../LangKey';
import { Theme } from '@mui/material/styles';

type MapStandInfoProps = {};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
  },
}));

const MapStandInfo = observer((props: MapStandInfoProps) => {
  const classes = useStyles();
  const stand = appModel.map.currentStand;
  const geometries = appModel.map.currentGeometries;

  if (!stand && !geometries) return null;
  const page = appModel.browser.page;
  const pageType = page.p === 'map' ? page.p1 : undefined;

  const onButtonClick = () => {
    if (!pageType) return;
    if (pageType === 'project' && stand) {
      return appModel.stands.showStand(stand);
    }
    if (pageType === 'freeGeometry' && geometries) {
      return appModel.browser.page = { p: 'freeGeometry', p1: `${geometries.id}` };
    }
  };

  return (
    <div className={classes.root}>
      <VBox gap={8}>
        <div>
          <Text variant={'h6'}>{pageType === 'project' ? stand?.name : geometries?.name}</Text>
        </div>
        <Button
          size={'small'}
          color={'primary'}
          variant={'contained'}
          onClick={onButtonClick}
        >
          {pageType === 'project' ? LangKey.StandStandInfo : LangKey.FreeGeometryInfo}
        </Button>
      </VBox>
    </div>
  );
});

export default MapStandInfo;
