import * as React from 'react';
import { Form, useError, useValue } from '@simosol/forms/lib/forms';
import { KeysOfType } from '@simosol/forms/lib/FormInput';
import {
  TextField,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldProps,
} from '@mui/material';

type OmitMuiTextFieldProps<K extends keyof TextFieldProps> =
  Omit<StandardTextFieldProps, K> |
  Omit<FilledTextFieldProps, K> |
  Omit<OutlinedTextFieldProps, K>;

export type FormTextFieldProps<T> =
  OmitMuiTextFieldProps<'value' | 'onChange' | 'onBlur' | 'error' | 'helperText'> &
  {
    model: Form<T>
    field: KeysOfType<T, string | undefined>,
    formatFunction?: (value: string | undefined) => string,
  };

const FormTextField = <T, >(props: FormTextFieldProps<T>) => {
  const { model, field, formatFunction, ...otherProps } = props;

  let value = useValue(model, field) as unknown as string | undefined;
  const err = useError(model, field);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    model.setValue(field, newValue as unknown as T[keyof T]);
  };

  const onBlur = () => {
    model.validate(field);
  };

  if (formatFunction) {
    value = formatFunction(value);
  }

  return (
    <TextField
      {...otherProps}
      variant={'standard'}
      onChange={onChange}
      value={value || ''}
      onBlur={onBlur}
      error={!!err}
      helperText={err || ' '}
    />
  );
};

export default FormTextField;
