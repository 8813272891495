import * as React from 'react';
import '../styles/boxes.scss';

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type BoxProps = {
  gap?: 0 | 4 | 8 | 16 | 24 | 32 | 48 | 64;
  align?: 'center' | 'end' | 'start';
} & DivProps;

const Box = (props: BoxProps & {type: 'HBox' | 'VBox'}) => {
  const { className, type, gap, align, ...otherProps } = props;
  const newGap = gap !== undefined ? gap : 16;
  let newClassName = type + '-' + newGap + (align !== undefined ? ' align_' + align : '');
  newClassName += className ? ' ' + className + ' ' : '';
  return (
    <div
      {...otherProps}
      className={newClassName}
    >
      {props.children}
    </div>
  );
};

export const HBox = (props: BoxProps) => <Box type={'HBox'} {...props}/>;
export const VBox = (props: BoxProps) => <Box type={'VBox'} {...props}/>;
