import * as React from 'react';
import { observer } from 'mobx-react';

import { appModel } from '../../models/App';
import IconButton from '../../uicomponents/IconButton';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawerPaper: {
    width: theme.appDrawer.width,
    backgroundColor: 'white',
  },
}));

type AppDrawerProps = {};
type AppDrawerPropsWithStyles = AppDrawerProps & TypographyProps;

const AppDrawerBase = observer((props: AppDrawerPropsWithStyles) => {
  const style = useStyles();
  return (
    <>
      <Hidden lgUp>
        <Drawer
          classes={{ paper: style.drawerPaper }}
          open={appModel.drawerOpen}
          onClose={() => appModel.drawerOpen = false}
        >
          {props.children}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer classes={{ paper: style.drawerPaper }} variant={'permanent'}>
          {props.children}
        </Drawer>
      </Hidden>
    </>
  );
});

export default AppDrawerBase;

type AppDrawerMenuButtonProps = {
  className?: string;
};

export const AppDrawerMenuButton = (props: AppDrawerMenuButtonProps) =>  (
  <IconButton color={'inherit'} className={props.className} onClick={() => appModel.drawerOpen = true}>
    <Menu/>
  </IconButton>
);
