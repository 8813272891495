import { appModel, DApp } from './App';
import { ApiMethodResponse, ErrorType } from '../API';
import DB from './DB';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import DataLoader from './DataLoader';

export default class DataSync {
  private _db: DB;
  private _isSyncing = false;
  private _syncAgain = false;

  private _isInitializing = false;

  private _syncReaction?: IReactionDisposer;
  private _syncIntervalId?: number;

  @observable
  criticalError?: string;

  @observable.ref
  initProgress: (() => number) | number = 0;

  constructor(db: DB) {
    makeObservable(this);
    this._db = db;
  }

  init = () => {
    reaction(
      () => this.authorized,
      (authorized) => {
        if (authorized) {
          this._sync();
        } else {
          // noinspection JSIgnoredPromiseFromCall
          this.onLogout();
        }
      },
      { fireImmediately: true },
    );
  }

  @computed
  get initialized() {
    const progress = typeof this.initProgress === 'number' ? this.initProgress : this.initProgress();
    return progress === 1;
  }

  @action
  onLogout = async () => {
    appModel.userRole = undefined;
    appModel.projects.clear();
    if (this._syncReaction) this._syncReaction();
    this._isSyncing = false;
    this._isInitializing = false;
    this._syncAgain = false;
    this.initProgress = 0;
    if (this._syncIntervalId) window.clearInterval(this._syncIntervalId);
    await this._db.destroyAndCreateNew();
  }

  /**
   * First time sync
   * @returns {Promise<void>}
   * @private
   */
  private _syncFirst = async () => {
    if (this.initialized || this._isInitializing) return;
    this._isInitializing = true;
    this.initProgress = 0;

    const standsCreationProgress = (startValue: number, endValue: number) => () => {
      const standsTotal = appModel.projects.standsTotal;
      if (standsTotal === undefined) return startValue;
      return appModel.projects.standsCreated / standsTotal * Math.max(endValue - startValue) + startValue;
    };

    // check if database is available
    let appData = undefined;
    try {
      const dbRes = await this._db.get();
      this.initProgress = 0.1;
      if (!this.authorized) return;
      appData = dbRes;
      // initialize application data first time from db
      this.initProgress = standsCreationProgress(0.1, 0.3);
      await this._updateFromData(appData);
    } catch (e: any) {
      if (e.status !== 404) {
        console.log('sync first error');
        console.log(e);
        // something went wrong, other error handling
        // TODO handle critical errors
      }
    }
    // when database available it could have some changes, sync it
    if (appData !== undefined) {
      await this._syncNext();
      if (!this.authorized) return;
    }

    // get new data from server
    const loader = new DataLoader();
    this.initProgress = () => {
      if (loader.partsTotal === undefined) return 0.3;
      return 0.3 + loader.partsLoaded / loader.partsTotal * 0.5;
    };

    const getDataRes = await loader.load();
    if (!this.authorized) return;
    if (!getDataRes.error && getDataRes.data) {
      // with network and new data, update from this data
      appData = getDataRes.data;
      await this._db.updateOrInsert(appData);
      // initialize application data second time from external source
      // todo compare and update only if data was really changed
      this.initProgress = standsCreationProgress(0.8, 0.95);
      await this._updateFromData(appData);
    } else {
      // if there is no network
      if (getDataRes.error && getDataRes.error.type === ErrorType.network) {
        // in offline mode without internal db we can't show anything
        if (!appData) {
          // todo handle network error
        }
      } else {
        // todo handle critical errors
      }
    }

    this._isInitializing = false;
    this.initProgress = 1;

    if (this._syncReaction) this._syncReaction();
    this._syncReaction = reaction(
      () => this.projects.version,
      (version: number) => {
        if (version === 0) return;
        this._sync();
      },
      { fireImmediately: false });

    if (this._syncIntervalId) window.clearInterval(this._syncIntervalId);
    this._syncIntervalId = window.setInterval(() => this._sync(), 6000);
  }

  /*
  Second time sync
   */
  private _syncNext = async () => {
    const data = this.getAppData();

    if (!data) return;

    const projectsVersion = this.projects.version;
    const fgVersion = this.freeGeometry.version;

    const unsavedData = this.unsavedData;
    // must be divided, because different endpoints
    const unsavedFG = appModel.freeGeometries.getUnsavedFGs();
    // check if app has unsaved data
    if (unsavedData === undefined && unsavedFG.length === 0) {
      return;
    }
    // if we are already syncing, we should sync again after current sync is complete
    if (this._isSyncing) {
      this._syncAgain = true;
      return;
    }
    // set the syncing flag
    this._isSyncing = true;

    const checkResponse = (res: ApiMethodResponse) => {
      if (!this.authorized) return;
      const networkError = res.error && res.error.type === ErrorType.network;
      if (res.error && !networkError) {
        // if some other error, except network error happened
        this.criticalError = res.error.message;
        this._isSyncing = false;
        this._syncAgain = false;
        return;
      }
      return networkError;
    };

    if (unsavedData) {
      // send unsaved data to server
      const resSetData = await appModel.api.setDataChanged(unsavedData);
      const networkError = checkResponse(resSetData);

      // without version checking it may happen when data was changed while sending the request.
      // we can't commit this changed data. we can only commit it after successful sending to the server
      if (!networkError && projectsVersion === this.projects.version) {
        this._commit();
      }
    }

    if (unsavedFG) {
      let networkError;
      await Promise.all(unsavedFG.map(async (fg) => {
        const contents = await appModel.api.setGetFreeGeometry(fg);
        networkError = checkResponse(contents);
      }));
      if (!networkError && fgVersion === this.freeGeometry.version) {
        this._fgCommit();
      }
    }

    try {
      await this._db.updateOrInsert(data);
    } catch (e) {
      console.log('sync next error', e);
      throw (e);
    }

    if (!this.authorized) return;

    this._isSyncing = false;
    if (this._syncAgain) {
      this._syncAgain = false;

      await this._syncNext();
      if (!this.authorized) return;
    }
  }

  @computed
  get unsavedData() {
    const tasks = appModel.tasks;
    const uNotes = tasks.getUnsavedNotes();
    const uProjects = appModel.projects.changedData;
    if (uNotes.length === 0 && Object.keys(uProjects).length === 0) return undefined;
    return { notes: uNotes, projects: uProjects };
  }

  private _commit = () => {
    this.projects.commit();
  }
  private _fgCommit = () => {
    this.freeGeometry.commit();
  }

  @computed
  get projects() {
    return appModel.projects;
  }
  @computed
  get freeGeometry() {
    return appModel.freeGeometries;
  }

  getAppData = (): DApp | undefined => {
    return appModel.getAppData();
  }
  @action
  private _updateFromData = async (data: DApp) => {
    return appModel.updateFromData(data);
  }

  @computed
  get authorized() {
    return appModel.authorized;
  }

  private _sync = () => {
    if (this.initialized) {
      // noinspection JSIgnoredPromiseFromCall
      this._syncNext();
    } else {
      // noinspection JSIgnoredPromiseFromCall
      this._syncFirst();
    }
  }
}
