import * as React from 'react';
import { default as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { appModel } from '../models/App';
import { Page } from '../Browser';
import { Utils } from '../Lang';

export type ButtonProps = MuiButtonProps & {
  page?: Page,
  loading?: boolean,
};

const Button = (props: ButtonProps) => {
  const { loading, page, ...newProps } = props;
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) props.onClick(e);
    if (page !== undefined && appModel.browser.page !== page) {
      appModel.browser.page = page;
    }
  };
  const disabled = newProps.disabled || loading;

  return (
    <MuiButton {...newProps} onClick={onClick} disabled={disabled}>
      {Utils.translateStringChildren(newProps.children)}
      {loading && <CircularProgress
        className="circularProgress"
        size={24}
        style={{ pointerEvents: 'none', color: '#FFF', position: 'absolute', right: 8, top: '50%', marginTop: -12 }}
      />}
    </MuiButton>
  );
};

export default Button;
