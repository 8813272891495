import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

type CenterContainerProps = {} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  box: {
    position: 'absolute',
    width: '100%',
    minHeight: 'calc(100% - 16px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
}));

const CenterContainer = (props: CenterContainerProps) => {
  const { children } = props;
  const styles = useStyles();
  return (
    <>
      <div className={styles.root} />
      <div className={styles.box} >
        {children}
      </div>
    </>
  );
};

export default CenterContainer;
